"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.splitCollectionAndNonce = void 0;
const uris_1 = require("./uris");
const utils_1 = require("./utils");
class ItemsDatabase {
    constructor(_items) {
        this._items = _items;
        this._cachedSlotSupply = new Map();
    }
    get items() {
        return Array.from(this._items);
    }
    static fromJson(parsedItems, useDevnetCollection) {
        const items = [];
        for (const parsedItem of parsedItems) {
            const { id, name, collection: mainnetCollection, nonce: mainnetNonce, devnetCollection, devnetNonce, attributeName, slot, renderUrl, supply, stakePoints } = parsedItem;
            if (name == "Default")
                break;
            if (!id) {
                console.error(`Item ${name} has no id`);
                continue;
            }
            const selectedCollection = useDevnetCollection ? devnetCollection : mainnetCollection;
            const selectedNonce = useDevnetCollection ? devnetNonce : mainnetNonce;
            if (!selectedCollection || !selectedNonce) {
                console.warn("No collection or nonce for id:", id, "in", useDevnetCollection ? "devnet" : "mainnet");
                continue;
            }
            const item = {
                id,
                type: "items",
                displayName: name,
                attributeName: attributeName,
                thumbnailUrls: {
                    high: (0, uris_1.getItemWebThumbnail)(id),
                    small: (0, uris_1.getItemWebThumbnail)(id), // TODO: use a small thumbnail
                },
                collection: selectedCollection,
                nonce: selectedNonce,
                identifier: (0, utils_1.toIdentifier)(selectedCollection, selectedNonce),
                slot,
                description: "",
                renderUrls: {
                    ipfs: renderUrl,
                    high: (0, uris_1.getRenderWebThumbnail)(id),
                },
                supply: supply !== null && supply !== void 0 ? supply : -1,
                stakePoints: stakePoints
            };
            items.push(item);
        }
        return new ItemsDatabase(items);
    }
    calculateItemScore(item) {
        return 1 / (item.supply / this.getSupplyOfSlot(item.slot));
    }
    calculatePenguinsScore(penguin) {
        let score = 0;
        for (const slot in penguin.equippedItems) {
            const item = penguin.equippedItems[slot];
            score += this.calculateItemScore(item);
        }
        return score;
    }
    getSupplyOfSlot(slot) {
        const cacheHit = this._cachedSlotSupply.get(slot);
        if (cacheHit != undefined) {
            return cacheHit;
        }
        const items = this._items.filter(i => i.slot == slot);
        const supply = items.reduce((acc, item) => acc + item.supply, 0);
        this._cachedSlotSupply.set(slot, supply);
        return supply;
    }
    idExist(id) {
        return this._items.some(item => item.id == id);
    }
    getItemFromId(id) {
        const item = this._items.find(item => item.id == id);
        if (!item) {
            throw new Error(`Item ${id} not found`);
        }
        return item;
    }
    getItemsOfSlot(slot) {
        return this._items.filter(item => item.slot == slot);
    }
    getItemFromAttributeName(attributeName, slot) {
        const item = this._items.find(item => item.attributeName == attributeName && item.slot == slot);
        if (!item) {
            throw new Error(`Unknown item with attributeName ${attributeName} for slot ${slot}`);
        }
        return item;
    }
    getItemFromIdentifier(identifier) {
        return this._items.find(i => i.identifier == identifier);
    }
    getItemFromTokenSilent(collection, nonce) {
        return this._items.find(item => item.collection === collection && item.nonce === nonce);
    }
    getItemFromToken(collection, nonce) {
        const item = this.getItemFromTokenSilent(collection, nonce);
        if (!item) {
            throw new Error(`Unknown item with collection ${collection} and nonce ${nonce}`);
        }
        return item;
    }
    getSlotFromIdentifier(identifier) {
        const item = this._items.find(item => item.identifier == identifier);
        if (!item)
            throw new Error(`No slot found for ${identifier}`);
        return item.slot;
    }
    getRandomItem() {
        const item = this._items[Math.floor(Math.random() * this._items.length)];
        return item;
    }
    getRandomItems(count) {
        const _items = Array.from(this._items);
        const output = [];
        for (let i = 0; i < count; i++) {
            const index = Math.floor(Math.random() * _items.length);
            output.push(_items[index]);
            _items.splice(index, 1);
        }
        return output;
    }
    getTokenFromItemID(id) {
        const item = this._items.find(i => i.id == id);
        if (!item)
            throw new Error("Item not found");
        if (!item.identifier)
            throw new Error("Item has no identifier");
        return splitCollectionAndNonce(item.identifier);
    }
    getUniqueItemsCollections() {
        const identifiers = {
            "background": [],
            "beak": [],
            "clothes": [],
            "eyes": [],
            "hat": [],
            "skin": [],
            "weapon": [],
        };
        for (const item of this._items) {
            if (!item.collection)
                continue;
            addCollection(item.slot, item.collection);
        }
        return identifiers;
        function addCollection(slot, identifier) {
            const currentArray = identifiers[slot] || [];
            // make sure there is no duplicates
            if (currentArray.includes(identifier))
                return;
            currentArray.push(identifier);
            identifiers[slot] = currentArray;
        }
    }
}
exports.default = ItemsDatabase;
// TODO: move to 
function splitCollectionAndNonce(identifier) {
    const splited = identifier.split('-');
    if (splited.length == 3) {
        return {
            collection: splited[0] + '-' + splited[1],
            nonce: parseInt(splited[2], 16)
        };
    }
    else {
        throw new Error(`Invalid identifier ${identifier}`);
    }
}
exports.splitCollectionAndNonce = splitCollectionAndNonce;
