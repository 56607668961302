import React from 'react';

const DiscordIcon = ({
    className = '',
    fill
}: {
    className?: string,
    fill?: string;
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.999 12" className={'icon ' + className} fill={fill}>
            <path d="M12.705,12.77a11.931,11.931,0,0,0-3.094-1c-.133.249-.289.584-.4.851a11,11,0,0,0-3.429,0,9.224,9.224,0,0,0-.4-.851,11.892,11.892,0,0,0-3.1,1.007A13.761,13.761,0,0,0,.064,21.753a12.226,12.226,0,0,0,3.8,2.01,9.823,9.823,0,0,0,.813-1.384,7.886,7.886,0,0,1-1.28-.644c.107-.082.212-.168.314-.257a8.491,8.491,0,0,0,7.588,0c.1.088.208.174.314.257a7.866,7.866,0,0,1-1.282.645,9.779,9.779,0,0,0,.813,1.384,12.2,12.2,0,0,0,3.8-2.011A13.742,13.742,0,0,0,12.705,12.77Zm-7.7,7.176A1.487,1.487,0,0,1,3.659,18.36a1.48,1.48,0,0,1,1.348-1.587A1.47,1.47,0,0,1,6.356,18.36,1.478,1.478,0,0,1,5.008,19.945Zm4.983,0A1.487,1.487,0,0,1,8.642,18.36a1.48,1.48,0,0,1,1.348-1.587,1.47,1.47,0,0,1,1.348,1.587A1.478,1.478,0,0,1,9.991,19.945Z" transform="translate(0 -11.765)" />
        </svg>
    );
};

export default DiscordIcon;
