"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ipfsGateway = exports.getPenguinWebThumbnail = exports.getRenderWebThumbnail = exports.getItemWebThumbnail = void 0;
const getItemWebThumbnail = (id) => {
    return `https://apc-items.s3.eu-west-3.amazonaws.com/thumbnail_web/${id}-thumbnail-web.jpg`;
};
exports.getItemWebThumbnail = getItemWebThumbnail;
const getRenderWebThumbnail = (id) => {
    return `https://apc-items.s3.eu-west-3.amazonaws.com/render_web/${id}-render-web-1024x1024.png`;
};
exports.getRenderWebThumbnail = getRenderWebThumbnail;
const getPenguinWebThumbnail = (cid) => {
    return `https://apc-penguins.s3.eu-west-3.amazonaws.com/${cid}-web.jpg`;
};
exports.getPenguinWebThumbnail = getPenguinWebThumbnail;
exports.ipfsGateway = "https://ipfs.io/ipfs/";
