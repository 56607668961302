"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numberToHex = exports.toIdentifier = void 0;
function toIdentifier(collection, nonce) {
    return `${collection}-${numberToHex(nonce)}`;
}
exports.toIdentifier = toIdentifier;
function numberToHex(n) {
    let hex = n.toString(16);
    if (hex.length % 2 !== 0) {
        hex = '0' + hex;
    }
    return hex;
}
exports.numberToHex = numberToHex;
