"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EggsDatabase = void 0;
class EggsDatabase {
    constructor(collection) {
        this.eggs = [];
        const diamondEgg = {
            tier: "diamond",
            id: "diamond",
            displayName: "Diamond Egg",
            thumbnailUrls: {
                high: "https://apc-eggs.s3.eu-west-3.amazonaws.com/eggs/diamond.png",
                small: "https://apc-eggs.s3.eu-west-3.amazonaws.com/eggs/diamond.png",
            },
            collection: collection,
            identifier: collection + "-01",
            nonce: 1,
            type: "eggs",
            supply: 615
        };
        const goldEgg = {
            tier: "gold",
            id: "gold",
            displayName: "Gold Egg",
            thumbnailUrls: {
                high: "https://apc-eggs.s3.eu-west-3.amazonaws.com/eggs/gold.png",
                small: "https://apc-eggs.s3.eu-west-3.amazonaws.com/eggs/gold.png",
            },
            collection: collection,
            identifier: collection + "-02",
            nonce: 2,
            type: "eggs",
            supply: 2085
        };
        const silverEgg = {
            tier: "silver",
            displayName: "Silver Egg",
            id: "silver",
            thumbnailUrls: {
                high: "https://apc-eggs.s3.eu-west-3.amazonaws.com/eggs/silver.png",
                small: "https://apc-eggs.s3.eu-west-3.amazonaws.com/eggs/silver.png",
            },
            collection: collection,
            identifier: collection + "-03",
            nonce: 3,
            type: "eggs",
            supply: 7300
        };
        this.eggs = [
            silverEgg,
            goldEgg,
            diamondEgg
        ];
    }
    getEggFromNonce(nonce) {
        const egg = this.eggs.find(egg => egg.nonce == nonce);
        if (!egg) {
            throw new Error("Egg not found");
        }
        return egg;
    }
    getEggFromTier(tier) {
        const egg = this.eggs.find(egg => egg.tier == tier);
        if (!egg) {
            throw new Error(`Can't find correspoding egg for tier ${tier}`);
        }
        return egg;
    }
    isTierValid(tier) {
        return this.eggs.some(egg => egg.tier == tier);
    }
}
exports.EggsDatabase = EggsDatabase;
