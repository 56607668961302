import React from 'react';

const ShareIcon = ({
    className = '',
    fill
}: {
    className?: string,
    fill?: string;
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.08 14.08" className={'icon ' + className} fill={fill}>
            <path d="M9.152.7a.7.7,0,0,1,.7-.7A4.224,4.224,0,0,1,14.08,4.225V9.857a4.224,4.224,0,0,1-4.224,4.224H4.224A4.224,4.224,0,0,1,0,9.857a.7.7,0,0,1,1.408,0,2.816,2.816,0,0,0,2.816,2.816H9.856a2.816,2.816,0,0,0,2.816-2.816V4.225A2.816,2.816,0,0,0,9.856,1.409a.7.7,0,0,1-.7-.7" />
            <path d="M18.548,29.426v1.987a.171.171,0,0,0,.295.118l2.916-3.045a.445.445,0,0,0,0-.615l-2.917-3.046a.171.171,0,0,0-.295.118l0,1.5a.171.171,0,0,1-.184.17,4.512,4.512,0,0,0-4.989,4.525.24.24,0,0,0,.434.148,4.976,4.976,0,0,1,2.245-1.75,4.924,4.924,0,0,1,2.347-.281.171.171,0,0,1,.15.17" transform="translate(-12.932 -23.956)" />
        </svg>
    );
};

export default ShareIcon;
