import React from 'react';

const RefreshIcon = ({
    className = '',
    fill
}: {
    className?: string,
    fill?: string;
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.749 13.749" className={'icon ' + className}>
            <g transform="translate(0.376 0.375)">
                <path d="M36.729,225a.591.591,0,0,0-.591.591,5.324,5.324,0,0,1-5.318,5.318,5.1,5.1,0,0,1-3.429-1.468,8.011,8.011,0,0,1-.631-.636h1.477a.591.591,0,0,0,0-1.182H25.521l-.029,0h-.007a.589.589,0,0,0-.074.015h0l-.036.011h0a.591.591,0,0,0-.071.03h0l-.031.017,0,0a.6.6,0,0,0-.06.041l-.007.005-.021.018-.007.006-.025.024,0,.005-.019.021-.007.008-.019.024,0,0-.021.03,0,0-.018.029v0h0a.589.589,0,0,0-.033.07v0a.586.586,0,0,0-.031.109v.006c0,.01,0,.02,0,.031v.007c0,.012,0,.024,0,.036v0c0,.009,0,.018,0,.027v2.954a.591.591,0,1,0,1.182,0v-1.276c.128.132.267.267.415.4a6.339,6.339,0,0,0,4.247,1.8,6.5,6.5,0,0,0,6.5-6.5.591.591,0,0,0-.591-.591" transform="translate(-24.321 -219.09)" fill={fill} stroke={fill} strokeWidth="0.75" />
                <path d="M12.408.331a.591.591,0,0,0-.591.591V2.233A7.858,7.858,0,0,0,6.513,0H6.472A6.487,6.487,0,0,0,0,6.5a.591.591,0,0,0,.591.59h0a.591.591,0,0,0,.59-.592A5.311,5.311,0,0,1,6.475,1.182h.017a6.676,6.676,0,0,1,4.7,2.1H9.784a.591.591,0,1,0,0,1.182H12.4a.591.591,0,0,0,.6-.545c0-.018,0-.037,0-.056V.922a.591.591,0,0,0-.591-.591" transform="translate(0)" fill={fill} stroke={fill} strokeWidth="0.75" />
            </g>
        </svg >

    );
};

export default RefreshIcon;

