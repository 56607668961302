"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Attributes = void 0;
const type_1 = require("./type");
class Attributes {
    constructor(entries) {
        this._map = new Map();
        if (entries) {
            for (const key in entries) {
                this.set(key, entries[key]);
            }
        }
    }
    get(slot) {
        return this._map.get(slot.toLowerCase());
    }
    set(slot, item) {
        this._map.set(slot.toLowerCase(), item);
    }
    static fromEndpointArgument(str) {
        const entries = str.split(";")
            .map(entry => entry.split(":"));
        const map = {};
        for (const [slot, item] of entries) {
            map[slot] = item;
        }
        return new Attributes(map);
    }
    toEndpointArgument() {
        const entries = Array.from(this._map.entries());
        for (const slot of type_1.iterablesSlots) {
            if (!this._map.has(slot)) {
                entries.push([slot, "unequipped"]);
            }
        }
        return entries
            .map(([slot, item]) => `${capitalizeSlot(slot)}:${item}`).join(";");
        function capitalizeSlot(slot) {
            return slot.charAt(0).toUpperCase() + slot.slice(1);
        }
    }
    toApiParameters() {
        if (this._map.size == 0)
            return "";
        const entries = Array.from(this._map.entries());
        return entries
            .map(([slot, item]) => `${slot}=${item.replace(" ", "%20")}`)
            .join("&");
    }
    equals(other) {
        const map1 = this._map;
        const map2 = other._map;
        const uniqueSlots = new Set([...map1.keys(), ...map2.keys()]);
        for (const slot of uniqueSlots) {
            const value1 = map1.get(slot) == "unequipped" ? undefined : map1.get(slot);
            const value2 = map2.get(slot) == "unequipped" ? undefined : map2.get(slot);
            if (value1 != value2) {
                return false;
            }
        }
        return true;
    }
}
exports.Attributes = Attributes;
